<template>
  <div>
    <b-sidebar
      @shown="shownSidebar"
      @hidden="hiddenSidebar"
      id="inquryEditSidebar"
      aria-labelledby="sidebar-no-header-title"
      body-class="bg-white"
      shadow
      style="height:130vh !important!; zoom:1.4; "
      width="635px"
      :backdrop="true"
      :right="true"
      z-index="9998"
    >
      <template #header="{ hide }">
        <div >
          <div class="sidebar-header d-flex align-items-center">
            <span class="icon">
              <v-icon :icon="['fac', 'edit']" />
            </span>
            <h4 id="sidebar-no-header-title" class="p-0 m-0">
             {{$t("myrequests.edit-req")}}
            </h4>
          </div>
        </div>
      </template>
      <template #default>
        <div
          v-if="loading"
          class="d-flex flex-column h-100 justify-content-center align-items-center"
        >
          <loading class="my-auto" :loading="loading" />
        </div>
        <div v-if="!loading" class="px-4 mt-4">
          <h3 class="inqury-edit-title my-4 d-none zoom"> {{$t("myrequests.category")}}</h3>

          <div class="form-group mb-5">
            <b-form-radio-group
                v-model="form.active"
                :options="statusOptions"
                class="mr-3"
                name="active"
            >
            </b-form-radio-group>
            
          </div>

          <div class="form-group mb-5">
            <label for="relatedIndustry"> {{$t("myrequests.label-0")}}</label>
            <multi-select
              track-by="name"
              id="relatedIndustry"
              label="name"
              :placeholder="$t('myrequests.ph1')"
              :clearOnSelect="false"
              :closeOnSelect="true"
              :allowEmpty="false"
              :showLabels="false"
              v-model="selectedIndustry"
              :options="industires"
            >
            </multi-select>
          </div>

          <validation-observer
            ref="editRequest"
            v-slot="{ touched, invalid, changed }"
          >
            <validation-provider
              class="w-100"
              name="Product Name"
              :rules="{ itemName: true }"
              v-slot="validationContext"
            >
              <div
                class="form-group mb-5"
                 :class="{
                    hasError:
                    (
                        !getValidationState(validationContext) 
                        &&
                        validationContext.errors[0]
                    )
                }"
              >
                <label for="itemName"
                  >{{$t("myrequests.label-1")}}
                  <small style="color: c5c5c5" class="ml-2">
                    {{$t("myrequests.label-desc")}}</small
                  >
                </label>
                <input spellcheck="true"
                  v-model="form.name"
                  type="text"
                  id="itemName"
                  class="form-control"
                />




<!-- ////////////////////////////New Requests Design////////////////// -->

                  <!-- <b-row rows="12" style="display:flex; justify-content:space-between">

                    <b-col cols="4">

                  <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Width:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                 </b-col>

                  <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Height:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>
                
                </b-col>

                <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Lenght:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                 
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                </b-col>

                   </b-row>


                  <b-row style="display:flex; justify-content:space-between">

                    <b-col cols="4">

                  <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Thichness:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                 
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                 </b-col>

                  <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px; "
                  >Color:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>
                
                </b-col>

                <b-col cols="4">

                   <div class="fff">

                 <label for="productName" style="margin-top:35px;"
                  >Use of Area:
                 
                  </label
                >
                <input spellcheck="true"
                  v-model.trim="item.name"
                  id="productName"
                  
                  type="text"
                  class="form-control"
                  style="width:100%; margin-top:0px;"
                />

                </div>

                </b-col>

                   </b-row> -->

        

             

<!-- ////////////////////////////New Requests Design////////////////// -->















                <div v-if="validationContext.errors[0]" class="text-danger">
                  {{ validationContext.errors[0] }}
                </div>
              </div>
            </validation-provider>
            <validation-provider
              class="w-100"
              name="Product Description"
              :rules="{ itemDescription: true }"
              v-slot="validationContext"
            >
              <div
                class="form-group mb-5"
                :class="{
                    hasError:
                    (
                        !getValidationState(validationContext) 
                        &&
                        validationContext.errors[0]
                    )
                }"
              >
                <label for="itemDesc">{{$t("myrequests.label-2")}}</label>
                <textarea spellcheck="true"
                  v-model="form.desc"
                  name=""
                  id="itemDesc"
                  cols="30"
                  rows="4"
                  class="form-control"
                >
                </textarea>
                 <div class="counter">
                <div v-if="validationContext.errors[0]" class="text-danger">
                  {{ validationContext.errors[0] }}
                </div>
                <div class="number-back-counter">
                  {{ counter }} / 1200
                </div>
                </div>
              </div>
            </validation-provider>
            <div class="form-group mb-5">
              <div class="form-group">
                <label for="unit">{{$t("myrequests.label-3")}}</label>
                <multi-select
                  id="unit"
                  track-by="name"
                  label="name"
                  :placeholder="$t('myrequests.unit')"
                  :clearOnSelect="false"
                  :closeOnSelect="true"
                  :allowEmpty="false"
                  :showLabels="false"
                  v-model="selectedUnit"
                  :options="units"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title text-capitalize">{{
                        props.option.name
                      }}</span>
                    </div>
                  </template>
                </multi-select>
              </div>
            </div>
            <validation-provider
              class="w-100"
              name="Demand"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <div
                class="form-group mb-5"
                :class="{
                  hasError:
                    !getValidationState(validationContext) &&
                    validationContext.errors[0],
                }"
              >
                <label for="demand">{{$t("myrequests.demand")}}</label>
                <input spellcheck="true"
                  v-model="form.demand"
                  id="demand"
                  placeholder="Monthly Capacity"
                  type="number"
                  class="form-control"
                />
                <div v-if="validationContext.errors[0]" class="text-danger">
                  {{ validationContext.errors[0] }}
                </div>
              </div>
            </validation-provider>
          </validation-observer>
        </div>
      </template>
      <template class="bg-white" #footer="{ hide }">
        <div
          class="d-flex bg-white shadow-lg justify-content-between align-items-center px-3 py-3"
        >
          <a @click.prevent="hide" href="#">{{$t("buttons.exit")}}</a>
          <button @click="updateItem" class="btn btn-primary btn-sm">
            {{$t("buttons.save")}}
          </button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: "inquryEdit",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      industires: [],
      selectedIndustry: null,
      units: [],
      selectedUnit: null,
      selectItem: {},
      statusOptions: [
        { text: this.$t('buttons.active'), value: true },
        { text: this.$t('buttons.passive'), value: false }
      ],
      form: {
        name: null,
        desc: null,
        demand: null,
      },
    };
  },
  computed: {
    counter() {
        return _.size(this.form.desc);
    }
  },
  methods: {
    async fetchIndustry() {
      try {
        let par = {
          type: "PRODUCT_AND_INQUIRY",
        };
        let response = await this.$store.dispatch("inquiries/getIndustries", {
          vm: this,
          payload: par,
        });
        this.industires = response;
      } catch (e) {
        console.log(e);
      }
    },
    async getUnits() {
      try {
        let response = await this.$store.dispatch("inquiries/getUnits", {
          vm: this,
        });
        this.units = response;
      } catch (e) {
        console.log(e);
      }
    },
    fetchData() {
      this.selectItem = this.item;

      this.form.active = this.item.active;
      //industry
      let industry_id = this.item.industry.id;
      this.selectedIndustry = this.industires.find(
        (item) => item.id === industry_id
      );
      // unit
      let unit_id = this.item.unit.id;
      this.selectedUnit = this.units.find((item) => item.id === unit_id);
      // name, description and demand
      let content = this.item.contents;
      this.form.name = this.getDefault(content).name;
      this.form.desc = this.getDefault(content).description;
      this.form.demand = this.item.demand;
    },
    async shownSidebar() {
      await this.fetchIndustry();
      await this.getUnits();
      this.loading = false;
      this.fetchData();
      console.log(this.selectItem);
    },
    async updateItem() {
      this.$refs.editRequest.validate().then(async (success) => {
        if (!success) {
          return;
        }

        this.loading = true;
        try {
          let payload = {
            id: this.item.id,
            industry_id: parseInt(this.selectedIndustry.id),
            name: this.form.name,
            unit_id: parseInt(this.selectedUnit.id),
            demand: parseInt(this.form.demand),
            description: this.form.desc,
            active: this.form.active,
          };
          let response = await this.$store.dispatch(
            "inquiries/updateInquryStatus",
            {
              vm: this,
              payload,
            }
          );
          let updateData = {
            id: this.item.id,
            demand: this.form.demand,
            industry: this.selectedIndustry,
            active: this.item.active,
            unit: this.selectedUnit,
            contents: [
              {
                default: true,
                name: this.form.name,
                description: this.form.desc,
              },
            ],
          };
          this.$emit("updated", updateData);
          this.$root.$emit("bv::toggle::collapse", "inquryEditSidebar");
          return this.$bvToast.toast(this.$t("Toaster.updated-request"), {
            title: this.$t("Toaster.success-title"),
            variant: "success",
            autoHideDelay: 1350,
          });
        } catch (e) {
          
          console.log(msg);
          return this.$bvToast.toast(this.$t(e.message), {
           title: this.$t("Toaster.error-title"),
            variant: "danger",
            autoHideDelay: 1350,
          });
        } finally {
          this.loading = false;
        }
      });
    },
    hiddenSidebar() {
      this.form = {};
      this.selectItem = {};
      this.loading = true;
      this.industires = [];
      this.selectedIndustry = null;
      this.units = [];
      this.selectUnits = null;
    }
  },
  created() {
  }
};
</script>
