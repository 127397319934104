<template>
  <div class="row">
    <div class="col-12">

      <div class="card-no-item">
        <div class="title">
          <p class="text-center">You haven’t posted any {{ userRoleType === 'BUYER' ? 'request' : 'listing' }} yet.</p>
        </div>
        <div class="row justify-content-center">
          <div v-for="item in getInfo" class="col-4">
            <div class="card-no-item-item">
              <div class="item-number">
                {{item.id}}
              </div>
              <h3 class="title">
                {{ item.title }}
              </h3>
              <span class="desc">
                 {{ item.desc }}
              </span>
              <div class="d-flex justify-content-center">
                <div class="icon">
                  <v-icon :icon="['fac', item.icon]"></v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="container-fluid p-0 m-0">
        <div class="row no-gutters">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <p class="page-footer-info">
              Start meeting new {{ userRoleType === 'BUYER' ? 'sellers' : 'buyers' }} now
            </p>
            <button @click="openAddItemModal" class="btn btn-primary btn-sm">
              {{ userRoleType === 'BUYER' ? 'Create a Request' : 'Make a Listing'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import itemAdd from "@/components/app/matching/component/itemAdd";
import itemAddInqury from "@/components/app/matching/component/itemAddInqury";


export default {
  components: {
    itemAdd,
    itemAddInqury
  },
  name: "emptyItem",
  data() {
    return {
      descItemBuyer : [
        {
          id: 1,
          title: "Create a Request",
          desc: "Provide a product name with a definition with relevant keywords to help us find the right seller.",
          icon: "ask"
        },
        {
          id: 2,
          title: "Get offers",
          desc: "Your RFQ will be listed in our databse to be served to suppliers who match your criteria.",
          icon: "offer"
        },
        {
          id: 3,
          title: "Contact Sellers",
          desc: "Once matched, you can engage the suppliers of your chosing and contact them within our platform.",
          icon: "connect"
        }
      ],
      descItemSeller : [
        {
          id: 1,
          title: "Post a listing",
          desc: "Enter the product name you are selling with description and with your selling price and the algorithm will do the rest.",
          icon: "ask"
        },
        {
          id: 2,
          title: "Get replies",
          desc: "Once your product is listed, your quotation will be served to eligible buyers who is in need of your products.",
          icon: "offer"
        },
        {
          id: 3,
          title: "Contact Buyers",
          desc: "You can engage the buyers of your chosing and contact them within our platform to give additonal information.",
          icon: "connect"
        }
      ]
    }
  },
  computed: {
    getInfo() {
      let type = this.userRoleType
      if(type === 'SELLER') {
        return this.descItemSeller
      }
      if(type === 'BUYER') {
        return this.descItemBuyer
      }
    }
  }
}
</script>