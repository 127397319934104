<template>
  <div class="container  my-quotes-tabs  calc10">
    <div class="row">
      <div class="col-12">
        <h3 class="page-title">{{ $t('myrequests.my-requests') }}</h3>
      </div>
    </div>
    <div class="row" v-if="alert.status">
      <div class="col-12">
        <div class="alert alert-danger">
          {{ alert.message }}
        </div>
      </div>
    </div>
    <loading :loading="loading" />
    <empty-item v-if="!loading && isEmpty" />
    <div
      v-if="!loading && !isEmpty"
      class="row no-gutters listing-badges tab-requ-quo  "
    >
      <div class="col-12">
        <b-tabs
          v-model="tabIndex"
          content-class="mt-4 w-100"
          class="requests__tabs"
        >
          <b-tab class="w-100">
            <template #title>
              <p class="">
                {{ $t('myrequests.public') }}
                <b-badge class="ml-20" variant="light">{{
                  countActive
                }}</b-badge>
              </p>
            </template>
            <div
              v-if="!loading && list.length > 0 && userRoleType === 'BUYER'"
              class="row no-gutters"
              style="margin-top: -60px !important"
            >
              <div class="col-12 mt-5">
                <div class="list-header d-flex w-100">
                  <b-row class="d-flex w-100" style="margin-left:16px;">
                    <b-col cols="4">
                      <span>{{ $t('myrequests.title-desc') }}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:0px;">
                      <span>{{ $t('myrequests.unit') }}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:-135px;">
                      <span>{{ $t('myrequests.demand') }}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:-30px;">
                      <span>{{ $t('myrequests.category') }}</span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="col-12 px-0">
              <div class="list-content">
                <div class="accordion" role="tablist" id="listViewAccordion">
                  <b-card
                    no-body
                    class="mb-0 mt-0"
                    v-for="(item, index) in list"
                    :key="item.id"
                    :style="item.disabled === true ? {opacity: 0.4, filter: 'alpha(opacity=40)'}: {}"
                  >
                    <b-card-header
                      header-tag="header"
                      class="p-1 list-view-headers"
                      role="tab"
                    >
                      <b-button
                        block
                        variant="info"
                        class="list-view-button"
                        aria-expanded="false"
                      >
                        <b-row
                          align-v="center"
                          id="member-table-list"
                          aria-expanded="false"
                        >
                          <b-col cols="4">
                            <h4>{{ getDefault(item.contents).name }}</h4>
                            <h6 style="width: 300px; margin-left:5px;">
                              <nl2br
                                tag="span"
                                :text="getDefault(item.contents).description"
                              />
                            </h6>
                          </b-col>
                          <b-col cols="2">
                            <h6 class="member-view-color">
                              {{ item.unit.shortname }}
                            </h6>
                          </b-col>
                          <b-col cols="2">
                            <h6 class="member-view-color">
                              {{ numberFixer(item.demand) }}
                            </h6>
                          </b-col>
                          <b-col cols="3"
                            class="d-flex industry-icon-requests"
                            style="margin-left: -25px !important"
                          >
                            <industry-icon
                              :color="'red'"
                              :name="item.industry.icon"
                              style="margin:0px !important"
                            />
                            <h6
                              class="member-view-color"
                              style="margin-top: 4px; margin-left: 10px"
                            >
                              {{ item.industry.name }}
                            </h6>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <a
                              href="javascript:void(0)"
                              v-b-toggle="'collapse' + item.id"
                              style="margin-right: 25px"
                            >
                              <img src="/img/member-accord.svg" alt="" />
                            </a>

                            <a
                              @click.prevent="openEditItem(index)"
                              class="ml-0"
                              href="#"
                              style="color: #1976D2;"
                            >
                              <v-icon :icon="['fac', 'edit']" class="mr-3" />
                            </a>
                          </b-col>
                        </b-row>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'collapse' + item.id"
                      :visible="false"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="collapse-requests"
                    >
                      <b-card-body>
                        <b-card-text>
                          <!-- <div class="member-view-close-button close-button-2">
                            <a href="#" v-b-toggle:accordion-1>{{
                              $t('myrequests.close')
                            }}</a>
                          </div> -->
                          <h1>{{ getDefault(item.contents).name }}</h1>
                          <b-row>
                            <b-col cols="8" class="mb-5">
                              <nl2br
                                tag="p"
                                :text="getDefault(item.contents).description"
                              />
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="4"
                              class="industry-icon-requests d-flex"
                            >
                              <industry-icon
                                :color="'red'"
                                :name="item.industry.icon"
                              />
                              <p class="margin-left:10px">
                                {{ item.industry.name }}
                              </p>
                            </b-col>

                            <b-col cols="2">
                              <p>
                                {{ $t('myrequests.unit') }}
                                <span> {{ item.unit.shortname }}</span>
                              </p>
                            </b-col>

                            <b-col cols="2">
                              <p>
                                {{ $t('myrequests.demand') }}
                                <span> {{ numberFixer(item.demand) }}</span>
                              </p>
                            </b-col>
                          </b-row>
                        </b-card-text>
                        <!--<b-card-text>{{ text }}</b-card-text> -->
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <p class="">
                {{ $t('myrequests.passive') }}
                <b-badge class="ml-20" variant="light">{{
                  countPassive
                }}</b-badge>
              </p>
            </template>
            <div class="row">
              <div class="col-md-12 mb-2" style="margin-top:15px;">
                <p
                  class="passive-text-info"
                  
                >
                  <img
                    src="/img/account-icons.png"
                    class="img-fluid mr-3"
                    alt=""
                    style="margin-top: -3px"
                  />
                  {{ $t('myrequests.passive-text') }}
                  <!-- <a href="" style="color: #198cff !important"
                    >{{ $t('buttons.learn') }}.</a
                  > -->
                </p>
              </div>
            </div>

            <div class="col-12 px-0">
              <div class="col-12">
                 <div v-if="list.lenght>0" class="list-header d-flex w-100">
                  <b-row class="d-flex w-100" style="margin-left:0px;">
                    <b-col cols="4">
                      <span>{{ $t('myrequests.title-desc') }}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:0px;">
                      <span>{{ $t('myrequests.unit') }}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:-125px;">
                      <span>{{ $t('myrequests.demand') }}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:-25px;">
                      <span>{{ $t('myrequests.category') }}</span>
                    </b-col>
                  </b-row>
                </div> 
              </div>
              <div class="list-content">
                <div
                  class="accordion"
                  role="tablist"
                  id="listViewAccordion"
                  style="opacity: 0.75 !important"
                >
                  <b-card
                    no-body
                    class="mb-0 mt-0"
                    v-for="(item, index) in list"
                    :key="item.id"
                    :style="item.disabled === true ? {opacity: 0.4, filter: 'alpha(opacity=40)'}: {}"
                  >
                    <b-card-header
                      header-tag="header"
                      class="p-1 list-view-headers"
                      role="tab"
                    >
                      <b-button
                        block
                        variant="info"
                        class="list-view-button"
                        aria-expanded="false"
                      >
                        <b-row align-v="center" id="member-table-list">
                          <b-col cols="4">
                            <h4>{{ getDefault(item.contents).name }}</h4>
                            <h6  style="width:300px;">
                              <nl2br
                                tag="span"
                                :text="getDefault(item.contents).description"
                               
                              />
                            </h6>
                          </b-col>
                          <b-col cols="2">
                            <h6 class="member-view-color">
                              {{ item.unit.shortname }}
                            </h6>
                          </b-col>
                          <b-col cols="2">
                            <h6 class="member-view-color">
                              {{ numberFixer(item.demand)}}
                            </h6>
                          </b-col>
                          <b-col
                            cols="4"
                            style="margin-left: -25px !important"
                            class="d-flex industry-icon-requests"
                          >
                            <industry-icon
                              :color="'red'"
                              :name="item.industry.icon"
                            />
                            <h6
                              class="member-view-color"
                              style="margin-top: 4px; margin-left: 10px"
                            >
                              {{ item.industry.name }}
                            </h6>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <a
                              href="javascript:void(0)"
                              style="margin-right: 25px"
                              v-b-toggle="'collapse' + item.id"
                            >
                              <img src="/img/member-accord.svg" alt="" />
                            </a>
                            <a
                              @click.prevent="openEditItem(index)"
                              class="ml-0"
                              href="#"
                              style="color: #1976D2;"
                            >
                              <v-icon :icon="['fac', 'edit']" class="mr-3" />
                            </a>
                          </b-col>
                        </b-row>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'collapse' + item.id"
                      :visible="false"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="collapse-requests"
                    >
                      <b-card-body>
                        <b-card-text>
                          <h1>{{ getDefault(item.contents).name }}</h1>
                          <!-- <div class="member-view-close-button close-button-2">
                            <a href="#" v-b-toggle:accordion-1>{{
                              $t('myrequests.close')
                            }}</a>
                          </div> -->

                          <b-row>
                            <b-col cols="8" class="mb-5">
                              <nl2br
                                tag="p"
                                :text="getDefault(item.contents).description"
                              />
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              cols="4"
                              class="industry-icon-requests d-flex"
                            >
                              <industry-icon
                                :color="'red'"
                                :name="item.industry.icon"
                                class="industry-icon-requests d-flex"
                              />
                              <p style="margin-left: 10px; margin-top: -2px">
                                {{ item.industry.name }}
                              </p>
                            </b-col>

                            <b-col cols="2">
                              <p>
                                {{ $t('myrequests.unit') }}
                                <span> {{ item.unit.shortname }}</span>
                              </p>
                            </b-col>

                            <b-col cols="2">
                              <p>
                                {{ $t('myrequests.demand') }}
                                <span> {{ numberFixer(item.demand) }}</span>
                              </p>
                            </b-col>
                          </b-row>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </b-tab>

          <template #tabs-end>
            <div class="requests__tabs__input-wrapper">
              <b-form-input
                v-model="filters.search"
                @keydown="searchOnKeyDown"
                class="nav-item"
                type="text"
                placeholder="Search"
              />
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
</svg>
            </div>
          </template>
        </b-tabs>
      </div>
    </div>
    <b-row class="gridview-pagination" v-if="!loading">
      <b-col cols="12">
        <b-pagination
          v-if="$_.size(list) > 0"
          class="mt-4"
          align="center"
          v-model="page"
          pills
          :total-rows="total"
          :per-page="limit"
          @change="onPageChange()"
        >
        </b-pagination>
      </b-col>
    </b-row>
    <inqury-edit-sidebar @updated="updateData" :item="selectedItem" />
  </div>
</template>

<script>
import emptyItem from '@/components/app/me/emptyItem';
import loading from '@/components/global/loading/index';
import getInquryCount from '@/graphql/me/getInquriesCount.graphql';
import inquryEditSidebar from '@/components/app/profile/inquryEditSidebar';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'my-requests',
  components: {
    emptyItem,
    loading,
    inquryEditSidebar,
  },
  data() {
    return {
      upadtedLoad: true,
      selectIndex: [],
      deleteIndex: [],
      checked: false,
      tabIndex: null,
      isEmpty: false,
      loading: true,
      page: 1,
      total: 0,
      limit: 12,
      list: [],
      filters: {
        active: true,
        search: null,
      },
      selectedItem: {},
      alert: {
        status: false,
        type: null,
        message: null,
      },
      countActive: null,
      countPassive: null,
      counts: null,
      searchOnKeyDown: null,
    };
  },
  computed: {
    ...mapGetters(['GET_ITEM_KEY']),
  },
  watch: {
    async tabIndex(val, oldVal) {
      this.list = [];
      if (oldVal !== null) {
        this.cursor = null;
        if (val === 0) {
          this.filters.active = true;
        }
        if (val === 1) {
          this.filters.active = false;
        }
        await this.getInquiryCount(true);
        await this.getInquiryCount(false);
        this.getInqiryList();
      }
    },
    GET_ITEM_KEY() {
        this.getInquiryCount(true);
        this.getInquiryCount(false);
        this.getInqiryList();
    }
  },
  methods: {
    async updateData(val) {
      this.upadtedLoad = false;
      let index = this.list.findIndex(item => item.id === val.id);
      if (index !== -1) {
        await this.$set(this.list, index, val);
        this.$nextTick(() => {
          this.upadtedLoad = true;
        });
      }

      this.cursor = null;

      await this.getInquiryCount(true);
      await this.getInquiryCount(false);
      await this.getInqiryList();
    },
    openEditItem(index) {
      this.selectedItem = this.list[index];
      this.$root.$emit('bv::toggle::collapse', 'inquryEditSidebar');
    },
    async itemStatusChange(index) {
      let x = this.list[index].active;

      let id = this.list[index].id;
      this.selectIndex.push(index);
      let dropref = 'dropdown' + index;

      let payload = {
        id: this.list[index].id,
        active: x,
      };
      try {
        let data = await this.$store.dispatch('inquiries/updateInquryStatus', {
          vm: this,
          payload,
        });
        this.$refs[dropref][0].hide(true);
        this.selectIndex = [];
        this.list = this.list.filter(item => item.id !== id);
      } catch (e) {
        console.log(e);
      }
    },

    async getInquiryCount(active = true) {
      try {
        this.alert.status = false;
        this.loading = true;

        let data = await this.$apollo.query(getInquryCount, {
          filters: {
            active,
          },
        });
        let count = data('**.count');

        if (active == true) {
          this.countActive = count;
        } else {
          this.countPassive = count;
        }

        /*if (count === 0) {
          return (this.isEmpty = true);
        } */
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        this.alert.status = true;
        this.alert.type = 'system';
        this.alert.message = msg;
      } finally {
        this.loading = false;
      }
    },
    async getInqiryList() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch(
          'inquiries/fetchInquiriesList',
          {
            vm: this,
            payload: {
              limit: this.limit,
              filters: this.filters,
              page: this.page,
            },
          }
        );
        this.total = response.total || 0;
        this.$set(this, 'list', response.list);
      } catch (e) {
        console.log(e);
      } finally {
          this.loading = false;
      }
    },
    onPageChange() {
      this.$nextTick()
        .then(() => this.getInqiryList())
        .then(() => this.appScrollTop());
    },
  },
  async created() {
    this.searchOnKeyDown = _.debounce(() => this.getInqiryList(), 1000);
    if (this.tabIndex === 0) {
      this.filters.active = true;
    }
    if (this.tabIndex === 1) {
      this.filters.active = false;
    }
    await this.getInquiryCount(true);
    await this.getInquiryCount(false);
    await this.getInqiryList();
  },
};
</script>
